
import { defineComponent, onMounted, ref } from "vue";
import ApiService from "@/core/services/ApiService";
import {
  translate,
  statusColor,
  checkPackagePlatform,
} from "@/core/services/HelpersFun";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";

// import UserAvatar from "@/components/UserAvatar.vue";
import platform from "@/components/Platform.vue";
import JwtService from "@/core/services/JwtService";
import MultiMessagesReportModal from "@/views/pages/services/bulkMessage/component/MultiMessagesReports.vue";

export default defineComponent({
  name: "kt-otp-management-table",
  components: {
    platform,
    MultiMessagesReportModal,
    // UserAvatar,
  },
  props: {
    widgetClasses: String,
  },
  setup() {
    const loading = ref(true);
    const bulkMessagesList = ref();
    const pagination = ref();
    const mobile = ref();
    const page = ref(1);
    const reportMpodal = ref(false);
    const botId = ref();

    const getItems = () => {
      loading.value = true;
      ApiService.query("message/archive-messages", {
        params: { with_dates: "yes", page: page.value },
      })
        .then(({ data }) => {
          console.log(data);

          loading.value = false;
          bulkMessagesList.value = data.data.messages.data;
          pagination.value = data.data.messages.pagination;
        })
        .catch(() => {
          loading.value = false;
        });
    };

    const excelLink = (id, param) => {
      return (
        process.env.VUE_APP_BACK_URL +
        `message/${id}/report/download?status=${param}&api_token=${JwtService.getToken()}&from_archive=1`
      );
    };

    const handleSizeChange = (val: number) => {
      return;
    };

    const handleCurrentChange = (val: number) => {
      page.value = val;
      getItems();
    };

    onMounted(() => {
      getItems();
      setCurrentPageBreadcrumbs(translate("آرشیو پیام ها"), []);
    });

    return {
      loading,
      pagination,
      getItems,
      translate,
      statusColor,
      mobile,
      bulkMessagesList,
      handleSizeChange,
      handleCurrentChange,
      checkPackagePlatform,
      excelLink,
      reportMpodal,
      botId,
    };
  },
});
